.blink_me {
	animation: blinker 1s linear infinite;
}
.react-datepicker__month-container{
	float: left;
}
@keyframes blinker {
	50% {
		opacity: 0.4;
	}
}
